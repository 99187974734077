* {
    margin: 00px;
    padding: 00px;
    box-sizing: content-box;
    text-align: center;
}

.schedule {
    margin-top: 4rem;
    height: 90%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6ebe0;
    flex-direction: column;
}

.schedule_main {
    display: flex;
    flex-direction: column;
    width: 80vw;
    background: radial-gradient(circle at 50% 0%, rgb(171, 204, 255) 0%, #e6ebe0 100%);
    border-radius: 8px;
}

.schedule_main_title {
    font-size: 32px;
    margin: 2rem;
}

.schedule_main_events_shared {
    display: flex;
    flex-direction: row;
}

.schedule_main_events_shared_shorts {
    display: flex;
    flex-direction: column;
}

.schedule_main_events_shared_long {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 00;
}

.schedule_download {
    width: 24rem;
}

.button-3 {
    margin-top: 2rem;
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    padding: 12px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
}

.button-3 a {
    color: #fff;
    text-decoration: none;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.button-3:hover {
    background-color: #2c974b;
}

.button-3:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

.Powered {
    margin-top: 2rem;
}

@media screen and (max-width: 1030px) {
    body {
        margin: 2rem 0;
    }
}

@media screen and (max-width: 600px) {
    .schedule_main_events_shared {
        display: flex;
        flex-direction: column;
    }

    .schedule_download {
        width: 80vw;
    }

    .button-3 a {
        font-size: 18px;
        line-height: 24px;
    }
}