* {
    margin: 00px;
    padding: 00px;
    box-sizing: content-box;
}

.team_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6ebe0;
    flex-direction: row;
    flex-flow: wrap;
}

.team_font {
    height: 375px;
    width: 250px;
    position: relative;
    border-radius: 10px;
}

.team_top {
    height: 13%;
    width: 100%;
    background-color: #1c7ed6;
    position: relative;
    z-index: 5;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.team_bottom {
    height: 87%;
    width: 100%;
    background-color: white;
    position: absolute;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-top: 10px;
}

.team_bottom p {
    position: relative;
    /* top: 0px; */
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 30px;
    text-emphasis: spacing;
}

.team_bottom .team_desi {
    font-size: 12px;
    color: grey;
    font-weight: normal;
}

.team_bottom .team_no {
    font-size: 15px;
    font-weight: normal;
}

.team_back {
    height: 375px;
    width: 250px;
    border-radius: 10px;
    background-color: #1c7ed6;
}

.team_Details {
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 25px;
}


.team_details-info {
    color: white;
    text-align: left;
    padding: 5px;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    line-height: 22px;
}

.team_padding {
    padding-right: 20px;
}

.team_name-field {
    font-size: 25px;
}

.team_supervisor-field {
    margin-top: 20px;
    font-size: 25px;
}

.team_school-field {
    margin-top: 20px;
    font-size: 25px;
}

.team_bottom .team_name,
.team_bottom .team_info {
    font-size: 20px;
    font-weight: 300;
}

.team_info {
    text-transform: capitalize !important;
}

.team_student-separator {
    margin: 5% 0;
    border-radius: 2px;
    border: none;
    border-top: 2px double rgba(20, 47, 71, 0.3);
    height: 3px;
    width: 100%;
}

@media screen and (max-width:600px) {
    .team_container {
        flex-direction: column;
        column-gap: 20px;
        row-gap: 30px;
    }

    .team_container .team_font {
        margin-top: 5vh;
    }

    .team_padding {
        padding-right: 0px;
    }

    .team_back,
    .team_font {
        width: 80vw;
    }
}

@media (min-width: 600px) {
    .team_container {
        height: 90vh;
    }
}