.event {
    padding: 12px;
    margin: 6px;
    font-size: 18px;
    border-radius: 6px;
}

.event_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    height: 100%;
}

.event_time,
.event_info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.h_separator {
    width: 4px;
    border-radius: 18px;
    background-color: #12394a;
    margin: 0 1rem;
}